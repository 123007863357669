import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { AppContext } from "../ContextApi";

import {
  wellness_diagnostics,
  wellness_teleconsultation,
  wellness_medicine,
  wellness_dental,
  wellness_banner,
  nutritionicon,
} from "../Assets";
import {
  APP_DIAGNOSTICS_URL,
  APP_MEDICINE_URL,
  APP_TELECONSULT_URL,
  APP_DENTAL_URL,
  APP_NUTRIOTION_URL,
} from "../Constants";
import { toast } from "react-toastify";

const Wellness_Benefits = ({ appLinkToken }) => {
  return (
    <>
      <div className="employee-benefit-package-wrapper pt-0 pb-5">
        <div className="container-fluid">
          <div className="employee-benefit-health-title">
            <div className="benefits-title-header">
              <h2 className="text-center">Powered By Medibhai</h2>
              <h3 className="text-center">Wellness Benefits</h3>
            </div>
            <div className="employee-benefit-wellness-wrapper">
              <div className="row align-items-center">
                <div className="col-lg-5 mb-4 mb-lg-0">
                  <div className="employee-benefit-wellness-image">
                    <img
                      src={wellness_banner}
                      alt="wellness banner"
                      width="100%"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <Link
                        className={`wellness-card-link ${
                          !appLinkToken ? "disabled" : ""
                        }`}
                        to={
                          appLinkToken
                            ? `${APP_NUTRIOTION_URL}?token=${appLinkToken}&dept_id=10&href=${window.location.href}`
                            : "#"
                        }
                        onClick={(e) => {
                          if (!appLinkToken) {
                            e.preventDefault(); // Prevent navigation when appLinkToken is null
                            toast.error(
                              "App link token is missing. Cannot proceed."
                            );
                          }
                        }}
                      >
                        <div className="employee-benefit-wellness-cover">
                          <div className="employee-benefit-wellness-box">
                            <div className="row">
                              <div className="col-lg-3 col-3 employee-benefit-wellness-box-icon">
                                <img
                                  src={nutritionicon}
                                  alt="nutrition"
                                  width="100%"
                                />
                              </div>
                              <div className="col-lg-9 col-9">
                                <div className="employee-benefit-wellness-heading">
                                  <span>Nutrition Consultation</span>
                                  <div className="employee-benefit-wellness-heading-line" />
                                </div>
                                <p>
                                  Get your personalized nutrition plan today!
                                </p>
                                <div className="employee-benefit-wellness-btn">
                                  {appLinkToken && (
                                    <Link
                                      to={`${APP_NUTRIOTION_URL}?token=${appLinkToken}&dept_id=10&href=${window.location.href}`}
                                    >
                                      <FaLongArrowAltRight />
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <Link
                        className="wellness-card-link"
                        to={`${APP_MEDICINE_URL}?token=${appLinkToken}&href=${window.location.href}`}
                        onClick={(e) => {
                          if (!appLinkToken) {
                            e.preventDefault(); // Prevent navigation when appLinkToken is null
                            toast.error(
                              "App link token is missing. Cannot proceed."
                            );
                          }
                        }}
                      >
                        <div className="employee-benefit-wellness-cover">
                          <div className="employee-benefit-wellness-box">
                            <div className="row">
                              <div className="col-lg-3 col-3 employee-benefit-wellness-box-icon">
                                <img
                                  src={wellness_medicine}
                                  alt=""
                                  width="100%"
                                />
                              </div>
                              <div className="col-lg-9 col-9">
                                <div className="employee-benefit-wellness-heading">
                                  <span> Medicine</span>
                                  <div className="employee-benefit-wellness-heading-line" />
                                </div>
                                <p>Buy Now &amp; Save upto 60%</p>
                                <div className="employee-benefit-wellness-btn">
                                  {appLinkToken && (
                                    <Link
                                      to={`${APP_MEDICINE_URL}?token=${appLinkToken}&href=${window.location.href}`}
                                    >
                                      <FaLongArrowAltRight />
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <Link
                        className="wellness-card-link"
                        to={`${APP_DIAGNOSTICS_URL}?token=${appLinkToken}&href=${window.location.href}`}
                        onClick={(e) => {
                          if (!appLinkToken) {
                            e.preventDefault(); // Prevent navigation when appLinkToken is null
                            toast.error(
                              "App link token is missing. Cannot proceed."
                            );
                          }
                        }}
                      >
                        <div className="employee-benefit-wellness-cover">
                          <div className="employee-benefit-wellness-box">
                            <div className="row">
                              <div className="col-lg-3 col-3 employee-benefit-wellness-box-icon">
                                <img
                                  src={wellness_diagnostics}
                                  alt="wellness_diagnostics"
                                  width="100%"
                                />
                              </div>
                              <div className="col-lg-9 col-9">
                                <div className="employee-benefit-wellness-heading">
                                  <span> Diagnostic</span>
                                  <div className="employee-benefit-wellness-heading-line" />
                                </div>
                                <p>
                                  Unlock Diagnostic Care Now! Get upto 80%
                                  discount
                                </p>
                                <div className="employee-benefit-wellness-btn">
                                  {appLinkToken && (
                                    <Link
                                      to={`${APP_DIAGNOSTICS_URL}?token=${appLinkToken}&href=${window.location.href}`}
                                    >
                                      <FaLongArrowAltRight />
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <Link
                        className="wellness-card-link"
                        to={`${APP_TELECONSULT_URL}?token=${appLinkToken}&href=${window.location.href}`}
                        onClick={(e) => {
                          if (!appLinkToken) {
                            e.preventDefault(); // Prevent navigation when appLinkToken is null
                            toast.error(
                              "App link token is missing. Cannot proceed."
                            );
                          }
                        }}
                      >
                        <div className="employee-benefit-wellness-cover">
                          <div className="employee-benefit-wellness-box">
                            <div className="row">
                              <div className="col-lg-3 col-3 employee-benefit-wellness-box-icon">
                                <img
                                  src={wellness_teleconsultation}
                                  alt="teleconsultation"
                                  width="100%"
                                />
                              </div>
                              <div className="col-lg-9 col-9">
                                <div className="employee-benefit-wellness-heading">
                                  <span> Teleconsultation</span>
                                  <div className="employee-benefit-wellness-heading-line" />
                                </div>
                                <p>Talk to Doctor Instantly</p>
                                <div className="employee-benefit-wellness-btn">
                                  {appLinkToken && (
                                    <Link
                                      to={`${APP_TELECONSULT_URL}?token=${appLinkToken}&href=${window.location.href}`}
                                    >
                                      <FaLongArrowAltRight />
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wellness_Benefits;
